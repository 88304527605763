
import Page from '../views/Page.vue';
import { Component, Mixins } from 'vue-property-decorator';
import AdminCard from '../components/admin/AdminCard.vue';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { BAIconsMixin } from '@/mixins';

@Component({
	components: {
		AdminCard,
		Page,
	}
})
export default class AdminDashboard extends Mixins(BAIconsMixin) {
	goToUserAdmin(): void{
		this.$router.push({name: AdminRoutes.UserAdminDashboard});
	}

	goToAthleteAdmin(): void {
		this.$router.push({name: AdminRoutes.AthleteAdminDashboard});
	}

	goToSportAdmin(): void {
		this.$router.push({name: AdminRoutes.SportAdminDashboard});
	}

	goToPartnerAdmin(): void {
		this.$router.push({name: AdminRoutes.PartnerAdminDashboard});
	}

	goToBASchoolAdmin(): void {
		this.$router.push({name: AdminRoutes.BASchoolAdminDashboard});
	}

	goToTeamsAdmin(): void{
		this.$router.push({name: AdminRoutes.TeamAdminDashboard});
	}

	goToOrgsAdmin(): void{
		this.$router.push({name: AdminRoutes.OrganizationAdminDashboard});
	}

	goToEvents(): void{
		this.$router.push({name: AdminRoutes.BAEventAdmin});
	}

	goToEventsPhysical() {
		this.$router.push({name: AdminRoutes.EventPhysical});
	}

	goToSchoolSearch(): void{
		this.$router.push({name: AdminRoutes.SchoolSearchAdminDashboard});
	}

	goToCoachShares() {
		this.$router.push({name: AdminRoutes.CoachSharesAdminDashboard});
	}

	goToPhysicalAssessments(): void {
		this.$router.push({name: AdminRoutes.VerifiedAssessmentAdminDashboard});
	}

	goToScoutingReports(): void {
		this.$router.push({name: AdminRoutes.ScoutingReportsAdminDashboard});
	}

	goToMindsetReports(): void {
		this.$router.push({name: AdminRoutes.MindsetReportsAdminDashboard});
	}
}
